@media (max-width: 1920px) {
  .eagle-man-col img {
    height: auto;

    bottom: 36px;
  }

  #login-page .col-lg-6 {
    max-width: 380px;
    padding: 0 27px 0 15px;
  }

  .steps-checkbox {
    white-space: nowrap;
  }

  .checkmark {
    height: 26px;
    width: 30px;
  }

  #dashboard-cnt-area {
    padding-bottom: 170px;
  }

  .login-checkbox {
    margin-top: 0;
  }

  .steps-checkbox {
    font-size: 18px;
  }

  #discover-personality-steps button img {
    max-width: 62px;
  }

  .signup-error {
    font-size: 13px;
  }

  #signup-page .brand-logo img,
  #login-page .brand-logo img {
    /* max-width: 162px; */
  }

  #signup-page .login-signup-txt,
  p.login-signup-txt {
    font-size: 18px;
  }

  #signup-page form {
    margin-top: 47px;
  }

  /* #signup-page {
    background-position: 0px -278px;
  } */
  .signup-input-bx input,
  #login-page form input.form-control {
    height: 50px;
    font-size: 13px;
  }

  /* #login-page form input.form-control {
    height: 59px;
    font-size: 16px;
  } */
  #signup-page form p {
    font-size: 16px;
  }

  .form-btn {
    margin-top: 0;
  }

  .modal-text,
  .four-three-modal p,
  .four-four-modal p {
    font-size: 14px !important;
    line-height: 1.5;
    text-align: center;
  }

  .four-second-modal .discover-hand-icon img,
  .four-four-modal .login-pop-cnt img {
    max-width: 124px;
  }

  .four-second-modal .discover-main-screen-cnt a {
    margin-top: 10px;
  }

  .four-first-modal h3.modal-header-text,
  .four-second-modal h3.modal-header-text,
  .four-three-modal h3.modal-header-text-white {
    margin-bottom: 5px;
    font-size: 28px;
  }

  .four-last-modal .login-pop-cnt img,
  .four-three-modal .discover-hand-icon img,
  .four-four-modal .discover-hand-icon img {
    max-width: 245px;
    margin: auto;
  }

  .four-three-modal.modal-content-wrapper {
    margin-bottom: 0;
  }

  .four-last-modal .modal-close-stepone.modal-header,
  .four-four-modal .modal-close-stepone.modal-header {
    padding-bottom: 0 !important;
  }

  .four-last-modal button.action-button::before,
  .four-four-modal button.action-button::before {
    width: 85px;
    height: 97px;
    top: -37px;
    left: -100px;
  }

  .four-last-modal button.action-button::after,
  .four-four-modal button.action-button::after {
    width: 99px;
    height: 97px;
    top: -37px;
    right: -114px;
  }

  .three-last-modal.login-pop-cnt img {
    max-width: 162px;
  }

  .modal-dialog {
    max-width: 650px !important;
  }

  .four-last-modal button.action-button,
  .four-four-modal button.action-button {
    margin-top: 10px;
    font-size: 21px;
    padding: 4px 25px;
  }

  #job-role-steps .form-card h3,
  #perfect-job-poster .perfect-content h5,
  #perfect-job-poster .perfect-content h3,
  #skills-steps h3,
  #soft-skills-steps h3,
  .personality-looking h6,
  #feel-suite-best h3 {
    font-size: 44px;
    margin-top: 10;
  }

  .form-btn {
    height: 57px;
    font-size: 22px;
    max-width: 247px;
    width: 255px;
  }

  .form-btn[id="togglesorting"] {
    height: 40px;
    font-size: 22px;
    max-width: 247px;
    width: 170px;
}

.toogleRes {
  position: relative;
    bottom: 20px
}

  .signup-input-bx {
    margin-bottom: 25px;
  }

  .PhoneInput {
    height: 57px;
  }

  #login-page form .form-btn,
  .loading-btn,
  .graphics-btn {
    height: 57px;
    font-size: 22px;
  }

  #login-page form {
    max-width: 640px;
    margin: 28px auto 0 auto;
  }

  #login-page form h6 {
    font-size: 15px;
    line-height: 1.5;

    margin-top: 46px;
    margin-bottom: 3px;
  }

  #signup-page,
  #login-page {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    flex: 1;
  }

  #perspectv-login-screen h1 {
    /* font-size: 2.5rem; */
    margin-top: 25px;
  }

  p.subtitle-text,
  .discover-personality-top-cnt p {
    font-size: 1rem;
  }

  .tabs-btn ul li a {
    /* padding: 13px 37px;
    font-size: 15px; */
  }

  #perspectv-login-screen {
    padding-top: 40px;
  }

  .login-module-heading {
    font-size: 2.5rem;
    margin-bottom: 0 !important;
  }

  .discover-personality-top-cnt p {
    margin-top: 0px;
  }

  .login-module-heading {
    margin-top: 10px;
  }

  #discover-personality-steps:after {
    height: 350px;
  }

  .login-pop-cnt h3,
  h3.modal-header-text {
    font-size: 28px;
    line-height: 1.3;
  }

  .h3.modal-header-text {
    margin-bottom: 0px;
  }

  .modal-skip-button-jp img {
    max-width: 80px;
  }

  .modal-dialog,
  .modal-lg {
    max-width: 600px;
  }

  /* .modal-close-stepone.modal-header {
      padding-bottom: 155px;
    } */
  .imageContainer img {
    max-width: 131px;
  }

  .login-pop-step-1,
  .login-pop-step-2 {
    background-size: cover;
  }

  .login-pop-cnt p {
    line-height: 1.4;
    margin-bottom: 0;
  }

  #msform .next,
  .four-first-modal a img,
  .four-second-modal a img,
  .four-three-modal a img,
  .personality-looking a img,
  .add1 img .company-info-container a img {
    width: 58px;

    margin-top: 0px;
  }

  .modal-btn {
    margin-top: 0px;
    font-size: 17px;
    margin-bottom: 15px;
  }

  .login-pop-cnt h3 {
    margin: 10px 0;
  }

  .discover-personality-steps button img {
    max-width: 90px;
  }

  .image-margin-top {
    margin-top: -181px !important;
    max-width: 193px;
  }

  .modal-two-image-hand {
    padding-bottom: 177px !important;
  }

  .login-pop-step-2 .image-margin-top {
    max-width: 200px;
  }

  .login-pop-cnt {
    padding-top: 20px;
  }

  .discover-main-screen-cnt-jp {
    padding-top: 10px;
  }

  .discover-main-screen-cnt-jp .modal-content-wrapper {
    margin-top: 10px;
    padding-top: 10px !important;
    padding-bottom: 0px !important;
  }

  .eye-icon-jp img {
    max-width: 177px;
    margin: auto;
  }

  .eye-icon-jp {
    padding-bottom: 25px;
  }

  .discover-main-screen-cnt {
    padding-top: 20px;
  }

  .discover-main-screen-cnt .modal-content-wrapper {
    padding-top: 20px !important;
  }

  .discover-hand-icon img {
    margin-top: -36px;
    max-width: 144px;
  }

  .discover-main-screen-cnt a img {
    max-width: 62px;
  }

  #get-started-page img[alt='eagle'] {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .suite-best-wrapper .col {
    width: 25%;
    max-width: 25%;
  }

  .upload-your-video h3 {
    padding: 0 5%;
    font-size: 45px;
    line-height: 53px;
  }

  .eagle-man-col img {
    width: 254px;
    height: auto;
    bottom: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .recent-profile {
    min-height: 145px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .upload-your-video h3 {
    padding: 0;
    font-size: 45px;
    line-height: 53px;
  }

  .eagle-man-col img {
    width: 220px;
    height: auto;
    bottom: 15px;
  }

  .dashboard-sidebar ul li a {
    font-size: 15px;
    line-height: 20px;
    padding: 15px 13px;
  }

  .agent-profile-cnt {
    display: block;
  }

  .agent-profile-text {
    margin-top: 15px;
  }

  .agent-profile-complete {
    align-items: flex-start;
    padding-top: 25px;
  }

  .agent-profile-complete a {
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  #perspectv-login-screen {
    padding-top: 122px;
  }

  p.subtitle-text {
    font-size: 19px;
    line-height: 1.6;
  }

  #perspectv-login-screen h1 {
    font-size: 3rem;
  }

  h1 {
    font-size: 42px;
    line-height: 50px;
    letter-spacing: -3px;
  }

  .discover-main-screen-cnt h6 {
    font-size: 36px;
    line-height: 47px;
    margin-bottom: 32px;
  }

  p {
    font-size: 15px;
    line-height: 24px;
  }

  .discover-personality-top-cnt p {
    margin-top: 15px;
  }

  .discover-personality-top-cnt {
    padding-top: 15px;
  }

  #msform nav .nav-tabs .nav-link {
    padding: 10px 40px;
  }

  #msform fieldset .form-card div#nav-tabContent {
    padding: 15px;
    margin-top: 40px;
  }

  .progress {
    width: 280px;
  }

  .steps {
    font-size: 18px;
    line-height: 18px;
  }

  .discover-personality-top-cnt h1 {
    padding: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.5px;
  }

  .discover-personality-top-cnt p {
    padding: 0 15px;
  }

  .login-module-heading {
    font-size: 42px;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: -4px;
    margin-bottom: 30px;
    margin-top: 0px;
    max-width: 80%;
    margin: auto;
  }

  #discover-personality-steps:after {
    height: 450px;
  }

  #signup-page {
    background-position: -655px -394px;
  }

  #get-started-page img[alt='eagle'] {
    position: absolute;
    bottom: 0;
    position: absolute;
    bottom: 0;
    right: 0px;
    width: 89%;
  }

  .skip-btn {
    position: absolute;
    top: 30px;
    right: 20px;
    font-weight: 500;
  }

  .tabs-btn ul li a {
    padding: 13px 12px;
    font-size: 15px;
  }

  #discover-personality-steps .modal-content .modal-body .form-card {
    padding: 15px 15px;
  }

  .login-pop-cnt h3 {
    letter-spacing: -2px;
    font-size: 26px;
    line-height: 34px;
  }

  #get-started-page h6 {
    font-size: 32px;
    line-height: 40px;
    margin-top: 40px;
  }

  .graphics-btn {
    margin-top: 0;
    padding: 0 29px;
    font-size: 30px;
  }

  .signup-verification-col {
    margin-bottom: 15px;
    padding: 0 15px;
  }

  #job-role-steps .form-card h3 {
    font-weight: bold;
    font-size: 38px;
    line-height: 46px;
  }

  .role-wrapper .inputGroup label {
    padding: 10px 8px;
    width: 100%;
    font-size: 15px;
  }

  .range-slider {
    padding: 0 10%;
  }

  .range-slider p {
    font-size: 42px;
  }

  #skills-steps h3,
  #soft-skills-steps h3 {
    font-size: 38px;
    letter-spacing: -2px;
    line-height: 46px;
  }

  .skill-range-slider .slidecontainer h6 {
    font-size: 17px;
    line-height: 22px;
  }

  .skill-range-slider p {
    font-size: 17px;
    line-height: 23px;
    margin-top: 10px;
  }

  #feel-suite-best h3 {
    font-size: 38px;
    line-height: 46px;
    letter-spacing: -2px;
    padding: 0;
  }

  .suite-best-wrapper .col {
    padding: 0 5px;
    width: 50%;
    max-width: 50%;
  }

  .upload-cv-col .custom-file {
    overflow: hidden;
  }

  .upload-your-video h3 {
    padding: 0;
  }

  .payment-success h4 {
    margin-top: 170px;
    line-height: 42px;
    font-size: 34px;
    font-weight: bold;
  }

  .dashboard-header:before {
    width: 100%;
  }

  .recent-profile {
    min-height: 163px;
  }

  .people-vector {
    position: fixed;
    bottom: 0;
    left: 0px;
  }

  /* #perspectv-login-screen img{
      max-width: 992px;
    } */
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 678px;
    margin: 1.75rem auto;
  }
}

@media (max-width: 576px) {
  .discover-check-cnt {
    /* max-width: 233px; */
  }

  .red-graphics-top {
    height: 255px;
  }

  .add-payment-method {
    margin-top: 172px;
  }

  #congrts-reacheds .perfect-content h5,
  #congrts-reacheds .perfect-content h3 {
    font-size: 33px;

    line-height: 1.4;
  }

  .people-vector {
    max-width: 1000px !important;
  }

  #job-role-steps .discover-personality-top-cnt {
    padding-top: 10px !important;
  }

  #job-role-steps {
    /* background-position: 0 410px; */
  }

  .discover-personality-top-cnt {
    /* padding-top: 117px; */
  }

  #perspectv-login-screen h1 {
    font-size: 2rem;
  }

  p.subtitle-text {
    font-size: 16px;
    /* line-height: 1.6; */
  }

  #login-page form {
    padding: 0;
  }

  #login-page form h3 {
    font-size: 28px;
    line-height: 36px;
  }

  #signup-page form .form-btn {
    width: 220px;
  }

  .signup-verification-col .wrapper {
    display: block;
  }

  .signup-verification-col .wrapper .option {
    margin: 22px 0px;
    padding: 0;
  }

  .role-wrapper .role-col {
    width: 100%;
  }

  .skill-range-slider {
    width: 100%;
  }

  .enter-hard-skills {
    padding: 0 15px;
  }

  .upload-cv-col {
    padding: 0px 5%;
  }

  #enhanced-profile button.next {
    font-size: 25px;
    padding: 12px 22px;
  }

  #enhanced-profile .next:before {
    left: -110px;
  }

  #enhanced-profile .next:after {
    right: -110px;
  }

  .upload-your-video h3 {
    font-size: 30px;
    line-height: 44px;
    margin-top: 30px;
  }

  .dashboard-header .col-12 {
    width: 50%;
    max-width: 50%;
  }

  .mircroshoft-heading {
    margin-top: 20px;
  }

  .mircroshoft-heading h4,
  .mircroshoft-heading p {
    text-align: center;
    line-height: 1;
  }

  .eagle-man-col img {
    z-index: -1;
    width: 282px;
    height: auto;
    bottom: -15px;
  }

  .fx-profile-card {
    background: white;
  }

  .agent-profile-cnt {
    margin-top: 30px;
  }

  .agent-profile-complete {
    justify-content: flex-start;
    margin-top: 20px;
  }

  .login-checkbox {
    margin-bottom: 40px;
    margin-top: 15px;
    padding: 0;
  }

  .steps-checkbox {
    padding: 5px 0 0 60px;
    text-align: left;
    font-size: 18px;
  }

  .login-module-heading {
    font-size: 40px;
  }

  p.subtitle-text,
  .discover-personality-top-cnt p {
    font-size: 16px;
  }

  .btn-primary img {
    width: 62px;
  }

  .login-module-heading {
    line-height: 40px;
    margin: 63px auto;
  }

  .tabs-btn ul li a {
    padding: 14px 25px;
    font-size: 16px;
  }

  .discover-personality-top-cnt img {
    width: 60px;
  }

  .login-screen-btn {
    margin-top: 19px;
  }

  .login-pop-cnt h3 {
    font-size: 21px;
    line-height: 1.4;
    padding: 0;
  }

  .modal-text {
    font-size: 14px !important;
  }

  .imageContainer img {
    max-width: 101px;
  }

  .imageContainer {
    margin-top: -127px;
  }

  .login-pop-step-2 .image-margin-top {
    max-width: 154px;
  }

  .login-pop-cnt .img-fluid {
    width: 132px;
  }

  .modal-btn {
    margin: 10px 0 10px;
  }

  .image-margin-top {
    margin-top: -101px !important;
  }

  .login-pop-cnt h3,
  h3.modal-header-text,
  h3.modal-header-text-white {
    font-size: 22px;
    margin-bottom: 0;
  }

  .eye-icon-jp img {
    max-width: 173px;
  }

  .modal-skip-button-jp img {
    max-width: 65px;
  }

  .eye-icon-jp {
    padding-bottom: 26px;
  }

  #msform .next {
    width: 55px;
  }

  .modal-content-wrap-four {
    width: 91% !important;
  }

  .discover-hand-icon img {
    max-width: 100px;
  }

  .discover-main-screen-cnt a img,
  .discover-main-screen-cnt-color-bg a img {
    max-width: 57px;
  }

  .discover-main-screen-cnt a {
    margin-top: 28px;
  }

  .modal-text-white {
    font-size: 14px !important;
  }

  .discover-main-screen-cnt-color-bg img {
    max-width: 78px;
  }

  .brand-logo img,
  .login-page img {
    max-width: 206px;
  }

  .signup-input-bx input,
  #login-page form input.form-control {
    height: 52px;
    font-size: 15px;
  }

  .form-btn,
  #login-page form .form-btn {
    height: 58px;
    font-size: 21px;
  }

  #signup-page .login-signup-txt,
  #login-page .login-signup-txt {
    font-size: 16px;
  }

  #signup-page form p,
  .enter-job-role input {
    font-size: 16px;
  }

  #signup-page form {
    margin-top: 41px;
  }

  .checkmark {
    height: 26px;
    width: 36px;
  }

  #job-role-steps .form-card h3,
  .enter-job-role h5 {
    font-size: 29px;
    line-height: 1.4;
  }

  .dashboard-sidebar ul li a {
    font-size: 16px;

    padding: 16px 17px;
  }

  #perfect-job-poster .perfect-content h5 {
    font-size: 30px;
    margin-top: 6px;
    padding: 0;
    line-height: 1.5;
  }

  #perfect-job-poster .perfect-content h3 {
    font-size: 37px;
    margin-top: 18px;
  }

  .menu-area ul li a {
    font-size: 16px;
  }

  .role-wrapper,
  .dashboard-sidebar ul {
    padding: 30px;
  }

  #dashboard-cnt-area {
    padding-top: 0;
  }

  .dashboard-sidebar>div {
    padding: 30px;
    margin-top: 15%;
  }

  #job-role-steps .form-card h3 {
    margin-bottom: 0;
  }

  .fx-profile-card .fx-card-cnt .fx-profile-text h6 {
    font-size: 20px;
  }

  .enter-job-role {
    margin-top: 0;
  }

  #skills-steps h3,
  #soft-skills-steps h3 {
    font-size: 31px;

    line-height: 1.4;
  }

  #skills-steps h3,
  #soft-skills-steps h3 {
    /* margin-bottom: 0; */
  }

  .personality-looking h6 {
    font-size: 37px;
    padding: 0;
  }

  #msform nav .nav-tabs .nav-link {
    font-size: 20px;
  }

  .discover-check-cnt p {
    padding-top: 0;
  }

  #feel-suite-best h3 {
    font-size: 30px;
  }

  .upload-your-profile h4,
  .upload-your-profile h6 {
    font-size: 24px;
  }

  #feel-suite-best h3 {
    margin-top: 23px;
    margin-bottom: 16px;
  }

  .upload-your-profile a img {
    max-width: 70px;
  }
}

.emailVerify-page {
  background-position: -369px -511px !important;
}