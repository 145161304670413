@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.min.css");
/* Remove Space Mono import if present
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
*/

/* Set the global default font */
body {
  font-family: "Space Mono", monospace;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: rgba(36, 45, 73, 0.65);
  --profileShadow: 0px 4px 17px 2px rgba(0, 0, 0, 0.25);
  --hrColor: #cfcdcd;
  --cardColor: rgba(255, 255, 255, 0.64);
  --buttonBg: linear-gradient(98.63deg, #f9a225 0%, #f95f35 100%);
  --inputColor: rgba(40, 52, 62, 0.07);
  --photo: #4cb256;
  --video: #4a4eb7;
  --location: #ef5757;
  --shedule: #e1ae4a;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-autosuggest__container {
  position: relative;
  margin-bottom: 200px;
}

.react-autosuggest__input {
  width: 350px !important;
  height: 50px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif; /* Ensures this input uses the default font */
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 350px !important;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.jobdetail_logo {
  height: 90px;
  max-width: 100%;
}

.current-job-col {
  cursor: pointer;
}

.fx-profile-card {
  cursor: pointer;
}

.like-deslike-btns a {
  cursor: pointer;
}

.personal_label {
  margin-bottom: 15px !important;
}

.text-left-bio-content {
  text-align: left;
}

.terms-conditions {
  padding: 1rem;
}

.terms-conditions h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.terms-conditions ul {
  margin: 0;
  padding: 0;
}

.terms-conditions li {
  margin-bottom: 0.5rem;
}

.privacy-policy {
  padding: 1rem;
  max-width: 800px;
  margin: auto;
}

.privacy-policy h1 {
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.privacy-policy h2 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 0.2rem;
}

.privacy-policy p {
  font-size: 1rem;
}

.capitalize {
  text-transform: capitalize;
}

.opacity-80 {
  opacity: 0.8 !important;
}
.opacity-70 {
  opacity: 0.7 !important;
}
.opacity-60 {
  opacity: 0.6 !important;
}
.opacity-50 {
  opacity: 0.5 !important;
}
.opacity-40 {
  opacity: 0.4 !important;
}
.opacity-30 {
  opacity: 0.3 !important;
}
.opacity-20 {
  opacity: 0.2 !important;
}
.opacity-10 {
  opacity: 0.1 !important;
}

.match-card-image {
  border-radius: 15px;
  max-height: 130px !important;
  max-width: 130px !important;
  object-fit: contain; /* ensures the image maintains aspect ratio and fits within the bounds */
}

.match-desc-image {
  border-radius: 15px;
  max-height: 200px !important;
  max-width: 200px !important;
  object-fit: contain; /* ensures the image maintains aspect ratio and fits within the bounds */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .match-card-image {
    max-height: 80px !important;   /* Smaller height for mobile devices */
    max-width: 80px !important;    /* Smaller width for mobile devices */
  }

  .match-card{
    padding: 5px 10px !important;
  }
  .match-card h5{
    font-size: smaller !important;
  }
  .match-card-per-text{
    font-size: smaller !important;
  }
  
}

.match-card-image-container {
  /* Set the size of the container if needed */
  width: 300px; /* or any other width */
  height: 200px; /* or any other height */
  overflow: hidden; /* This ensures the image doesn't overflow the container */
  position: relative; /* This helps in positioning the image within the container */
}

.match-card-image-container img {
  width: 100%;
  height: auto; 
  display: block; 
  object-fit: cover; 
}

/* Remove or adjust Space Mono classes as needed */
.space-mono-regular {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: normal;
}

.space-mono-bold {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: normal;
}

.space-mono-regular-italic {
  font-family: "Space Mono", monospace;
  font-weight: 400;
  font-style: italic;
}

.space-mono-bold-italic {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  font-style: italic;
}

.btn-boost-profile {
  /* Remove Space Mono if not needed */
  /* font-family: 'Space Mono', monospace !important; */
  /* Alternatively, set to default or another desired font */
  font-family: Helvetica, Arial, sans-serif !important;
}

.boost-yellow{
  color: var(--yellow);
}