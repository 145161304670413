.dropdownContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown {
    width: 200px;
    border-radius: 3px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .1);
    background-color: white;
    border: 1px solid black;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.dropdown-header {
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-body {
    padding: 5px;
    border-top: 1px solid #000000;
    display: none;
}

.dropdown-body.open {
    display: block;
    /* height: ; */
    max-height: 120px;
    overflow-y: auto;
}

.dropdown-item {
    padding: 10px;
}

.dropdown-item:hover {
    cursor: pointer;
}

.dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
}

.dropdown-item-dot.selected {
    opacity: 1;
}

.icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
}

.icon.open {
    transform: rotate(90deg);
}