.Chat {
  position: relative;
  display: grid;
  grid-template-columns: 22% auto;
  gap: 1rem;
  /* margin-top: 10px; */
  background: #f3f3f3;
  padding: 1rem 1rem;
}
.Chat-container h2 {
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  padding: 20px 0;
}
/* .content-wrap{
  padding: 1rem 0 !important;
} */
.conversation hr {
  margin-top: 3px;
  margin-bottom: 3px;
}
/* .blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: aliceblue;
  filter: blur(72px);
} */

.navIcons {
  /* margin-top: 10px; */
  display: flex;
  justify-content: space-around;
  gap: 24px;
}

.navIcons > a > img {
  width: 1.5rem;
  height: 1.5rem;
}

.Left-side-chat {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.follower {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.follower > div {
  display: flex;
  gap: 10px;
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.name > span:nth-of-type(1) {
  font-weight: bold;
}

.Chat-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: var(--cardColor);
  border-radius: 1rem;
  padding: 1rem;
  height: auto;
  min-height: 80vh;
  overflow: auto;
}

.Chat-list {
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

.Right-side-chat {
  display: flex;
  flex-direction: column;
  /* gap: 1.6rem; */
}
.conversation:hover {
  background: #80808038;
  cursor: pointer;
}

.conversation {
  border-radius: 0.5rem;
  padding: 10px;
}

.conversation > div {
  position: relative;
}

.online-dot {
  background-color: greenyellow;
  border-radius: 50%;
  position: absolute;
  left: 2rem;
  width: 1rem;
  height: 1rem;
}

.chatbox-empty-message {
  display: flex;
  align-self: center;
  justify-content: center;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .Chat {
    grid-template-columns: auto;
  }
  .follower.conversation > div > .name {
    /* display: none; */
  }
}
.top-btn {
  border: 0;
}
.top-btn img {
  width: 20px;
}
.navIcons button {
  border: 0;
  background: transparent;
}
.navIcons button img {
  max-width: 36px;
}
.chat-bg {
  background: #ffd578;
  padding: 16px;
}
.serach-img img {
  max-width: 50px !important;
}
@media screen and (max-width: 576px) {
  .Chat-container h2 {
    font-size: 17px;
  }

  .Search > input {
    max-width: 141px;
  }
  .navIcons {
    gap: 0px;
  }
  .navIcons button img {
    max-width: 25px;
  }
}
/* .upload-img1 img {
  background: url("https://img.icons8.com/ios-glyphs/512/attach.png");
  background-repeat: no-repeat;
  background-position: center bottom;
} */
.message a {
  color: #fff;
}
.upload-img1 img {
  position: absolute;
}
.upload-img1 input {
  max-width: 30px;
  opacity: 0;
}

.conversation.active {
  background-color: gainsboro;
}
