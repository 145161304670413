.LogoSearch {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}
.Search {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  padding: 5px;
  height: 40px;
  justify-content: space-between;
}
.Search > input {
  background-color: transparent;
  border: none;
  outline: none;
}
.s-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffd578;
  border-radius: 5px;
  padding: 4px;
  color: white;
}
