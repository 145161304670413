.ChatBox-container {
  background: var(--cardColor);
  border-radius: 1rem;
  display: grid;
  grid-template-rows: 14vh 56vh 13vh;
}

.chat-header {
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
}

.chat-body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
  overflow: auto;
}

.message {
  background: #857979d9;
  color: white;
  padding: 0.7rem;
  border-radius: 1rem 1rem 1rem 0;
  max-width: 28rem;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.message > span:nth-child(2) {
  font-size: 0.7rem;
  color: var(--textColor);
  align-self: end;
}

.own {
  align-self: flex-end;
  border-radius: 1rem 1rem 0 1rem;
  color: black;
  background: #ffd578;
}

.chat-sender {
  background: white;
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  align-items: center;
  /* gap: 1rem; */
  padding: 0.8rem;
  border-radius: 1rem;
  align-self: end;
}
.chat-sender > input {
  height: 70%;
  background-color: rgb(236, 236, 236);
  border-radius: 0.5rem;
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  padding: 0px 15px 0px 15px;
}
.chat-sender > div:nth-of-type(1) {
  background: rgb(233, 233, 233);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
  padding: 6px;
  height: 39px;
}
.chat-sender > div {
  height: 70%;
  /* padding: 0px 15px 0px 15px; */
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #111;
  border: none;
  border-radius: 0.5rem;
  background: #ffd578;
  transition: all 100ms ease-out;
  font-size: 16px;
  padding: 15px 18px !important;
}
