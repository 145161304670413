.enhanced-profile-v2 {
    background: black;
    height: max-content;
}

#page {
    background: url('/public/boost-profile/boost-background.png') lightgray 50% / cover no-repeat;
    height: 100vh;
    position: relative;
    /* OTHER image = url('/public/boost-profile/dots-background.png') */
}

.logo{
    margin-top: 57px;
    /* width: 145px; */
    height: 65.436px;
    flex-shrink: 0;
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
    .logo {
        margin-top: 10px;
    }
}

.boost-text{
    color: #FF3C31;
    font-family: "Space Mono";
    font-size: 60.26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    margin-right: 35px;
    z-index: 15;
}

.boost-sub-text{
    color: #FFF;
    font-family: "Space Mono";
    font-size: 36.156px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    z-index: 15;
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
    .boost-text{
        font-size: 45.26px;
    }
    .boost-sub-text{
        font-size: 30.156px;
    }
}


.page-subtext-container{
    width: 463px;
    height: 39.553px;
    transform: rotate(-2.626deg);
    flex-shrink: 0;
    background: #FF3C31;
    z-index: 10;
}

.page-subtext-text{
    /* transform: rotate(-2.626deg); */
    color: #FFF;
    font-family: Poppins;
    font-size: 12.832px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
    .page-subtext-container{
        width: 95%;
        padding: 20px;
    }
    .page-subtext-text{
        font-weight: 600;
    }
}


.mobile-img {
    width: 100%; /* Set width relative to the parent container */
    max-width: 370px; /* Maximum width of the image */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 5%; /* Consistent bottom margin */

    position: absolute;
    /* bottom: 0; */
    z-index: 2;
}

.mobile-card {
    width: 100%; /* Set width relative to the parent container */
    max-width: 370px; /* Maximum width of the image */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 5%; /* Consistent bottom margin */
    font-size: 20px !important;
    position: absolute;
    bottom: 0;
    z-index: 2;
    background: #FFD477;
    box-shadow: 8px 8px 0px #050505; /* Hard shadow with no blur */
    transform: rotate(-2.626deg);
    padding-top: 20px;
    padding-bottom: 20px;
    transition: bottom 0.3s ease; /* Smooth transition for the bottom property */
}

@media (max-width: 768px) {
    .mobile-card {
        bottom: 7%; /* Move the card down further on smaller screens */
    }
}

@media (max-width: 480px) {
    .mobile-card {
        bottom: 7%; /* Move the card down even more on very small screens */
    }
}

@media (min-width: 769px) {
    .mobile-card {
        bottom: 0%; /* Default position for larger screens */
    }
}

@media (min-width: 900px) {
    .mobile-card {
        bottom: 0%; /* Default position for larger screens */
    }
}

@media (min-width: 1100px) {
    .mobile-card {
        bottom: -2%; /* Default position for larger screens */
    }
}

/* Smaller devices (phones, less than 768px) */
@media (max-width: 575.98px) {
    .mobile-img {
        width: 75% !important; /* Scale down to 60% of the original size */
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .mobile-img {
        /* width: 50% !important; */
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .mobile-img {
        /* width: 30% !important;  */
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .mobile-img {
        /* width: 25% !important; */
    }
}

.bomb-img {
    width: 25rem; /* Adjust the width as needed */
    margin-bottom: 5%; /* Move 15% of the image height behind the clouds */
    margin-left: 30%;

    position: absolute;
    bottom: 0;
    z-index: 2;
}

.clouds-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;
}

.clouds-container-img {
    width: 100%;
    height: 20rem;
}

.money-card {
    width: 211px;
    height: 69px;
    flex-shrink: 0;
    background: #FFF;
    position: relative;
    box-shadow: 8px 8px 0px #050505; /* Hard shadow with no blur */
    z-index: 15;
}

.money-card-text {
    color: #000;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

/* General styling for the row containing the stars */
.star-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

/* Styling for the group containing the small and medium stars */
.star-group {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-star {
    position: relative;
    top: -70px; /* Adjust as necessary */
    z-index: 1;
}

.medium-star {
    height: 104px;
    width: 104px;
    filter: drop-shadow(8px 8px 0px #050505);
    /* position: relative; */
    /* top: 0px;
    z-index: 1; */
}

.big-star {
    height: 134px;
    width: 134px;
    filter: drop-shadow(8px 8px 0px #050505);
    /* position: relative;
    top: 30px;
    z-index: 1; */
}

/* Responsive adjustments */
@media (max-width: 992px) {
    .big-star {
        top: 20px; /* Slightly raise the big star on smaller screens */
    }
}

@media (max-width: 768px) {
    .star-container {
        flex-direction: column;
        align-items: center;
    }

    .star-group {
        flex-direction: row;
    }

    .small-star {
        top: 0px; /* Reset positioning on very small screens */
        right: 40px;
    }

    .big-star {
        top: 50px; /* Adjust positioning for mobile */
        height: 60px; /* Scale down the size */
        width: 60px; /* Scale down the size */
    }
}